import axios from 'axios'
import util from '../util/asymmetric'

let token = util.asymmetric()

const callservice = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: { token: token },
})

callservice.interceptors.response.use(
  (response) => {
    if (response.data.error.code === 403) {
      console.log(response.data)
      alert('ติดต่อพนักงานของเราเพื่อขอความช่วยเหลือ')
      sessionStorage.clear()
      localStorage.clear()
      window.location.href = '/texp?errorCode=-2002'
    }

    return response
  },
  (error) => {
    console.log(error.message)
    window.location.href = '/texp?errorCode=-2003'
  }
)

export default callservice
