<template>
  <div>
    <table
      style="width: 100%; border-bottom: 1px solid #e5e5e5"
      class="table text-left small"
    >
      <tbody>
        <tr v-for="list in cartLists" :key="list.objectId">
          <td style="width: 20%">
            <img
              style="border-radius: 3px"
              :src="list.remoteImagePath"
              alt=""
              class="img-fluid"
            />
          </td>
          <td>
            {{ list.productSKU.SKUName }}
            <br />
            <small class="text-description">
              {{ list.quantitySKU }} x {{ list.productSKU.SKUPrice }}
            </small>
          </td>
          <td class="text-right">฿{{ list.totalAmount }}</td>
        </tr>
      </tbody>
    </table>
    <table style="width: 100%" class="text-left small">
      <tbody>
        <tr>
          <td style="padding-left: 5px">รวม</td>
          <td class="text-right">฿ totalCost</td>
        </tr>
        <tr>
          <td style="padding-left: 5px">ส่วนลด</td>
          <td class="text-right">-฿0.00</td>
        </tr>
        <tr>
          <td></td>
          <td class="text-right">
            <b> ฿ totalCost</b>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import util from '@/util/util'
import poscrm from '@/services/poscrm'

export default {
  data() {
    return {
      cartLists: [],
    }
  },
  computed: {
    ...mapGetters(['shopLineObjectId']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    objectId() {
      return this.$route.query.objectId
    },
  },
  mounted() {
    this.getOrderDetail()
  },
  methods: {
    getOrderDetail() {
      let params = {
        shopObjectId: this.shopLineObjectId,
        objectId: this.objectId,
      }

      poscrm
        .get('/api/v1.1/' + this.uid + '/Receipt/order/data', { params })
        .then((res) => {
          console.log(res.data)
          this.cartLists = res.data.data
        })
    },
  },
}
</script>
