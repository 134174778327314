<template>
  <CCard class="mt-3">
    <CCardBody
      border-color="greyborder"
      style="border-radius: 15px"
      class="text-center"
    >
      <div v-if="status === 'success'">
        <img
          src="../../../public/img/success.png"
          class="img-fluid"
          alt=""
          width="40%"
        />
        <h4 class="mt-4 font-weight-bold">สั่งซื้อสินค้าสำเร็จ</h4>
      </div>

      <div v-else-if="status === 'error'">
        <img
          src="../../../public/img/error.png"
          class="img-fluid"
          width="40%"
          alt=""
        />
        <h4 class="mt-4 font-weight-bold">ชำระเงินไม่สำเร็จ</h4>
      </div>

      <div v-else-if="status === 'pending'">
        <img
          src="../../../public/img/pending.png"
          class="img-fluid"
          width="40%"
          alt=""
        />
        <h4 class="mt-4 font-weight-bold">รอดำเนินการ</h4>
      </div>

      <p>{{ this.alertMessage }}</p>
      <order-detail></order-detail>
      <hr />

      <table style="width: 100%" class="table text-left">
        <tbody class="small">
          <tr>
            <td style="border-color: #fff">
              <b> รายละเอียดคำสั่งซื้อ </b>
            </td>
            <td style="border-color: #fff"></td>
          </tr>
          <tr>
            <td>เลขที่คำสั่งซื้อ</td>
            <td class="text-right">{{ orderId }}</td>
          </tr>
          <tr>
            <td>เวลาสั่งซื้อ</td>
            <td class="text-right">{{ createdAt }}</td>
          </tr>
          <tr>
            <td>วิธีการชำระ</td>
            <td class="text-right">{{ sourceType }}</td>
          </tr>
          <tr>
            <td>ยอดชำระ</td>
            <td class="text-right">{{ amount }} THB</td>
          </tr>
          <tr>
            <td>สถานะการชำระ</td>
            <td>
              <h6
                class="small font-weight-bold text-right text-success"
                v-if="status === 'success'"
              >
                {{ alertMessage }}
              </h6>
              <h6
                class="small font-weight-bold text-right text-danger"
                v-else-if="status === 'error'"
              >
                {{ alertMessage }}
              </h6>
              <h6
                class="small font-weight-bold text-right text-warning"
                v-else-if="status === 'pending'"
              >
                {{ alertMessage }}
              </h6>
            </td>
          </tr>
        </tbody>
      </table>

      <div v-if="status === 'success'">
        <button class="btn btn-dark btn-block mt-1" @click="goToShop()">
          กลับไปยังร้านค้า
        </button>
      </div>
      <div v-else-if="status === 'error'">
        <button class="btn btn-danger btn-block mt-1" @click="goToPayment()">
          ชำระเงิน {{ amount }} THB
        </button>
        <button class="btn btn-outline-dark btn-block mt-1">
          ยกเลิกคำสั่งซื้อ
        </button>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapGetters } from 'vuex'
import payment from '@/services/payment'
import OrderDetail from './OrderDetail'
import util from '@/util/util'
import moment from 'moment'

export default {
  components: { OrderDetail },
  data() {
    return {
      status: '',
      charge: null,
      alertMessage: '',
    }
  },
  computed: {
    ...mapGetters(['shopLineOA', 'shopLineObjectId']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    shop() {
      return this.shopLineOA.find(
        (detail) => detail.objectId === this.shopLineObjectId
      )
    },
    amount() {
      if (this.charge !== null) {
        let amountData = this.charge.amount / 100
        this.totalAmountForPayment = amountData
        return util.convertCurrency(amountData)
      }
    },
    createdAt() {
      if (this.charge !== null) {
        return moment(this.charge.created_at).format('DD/MM/YYYY HH:mm A')
      }
    },
    orderId() {
      if (this.charge !== null) {
        return this.charge.metadata.order_id
      }
    },
    sourceType() {
      if (this.charge !== null) {
        let source = this.charge.source

        if (source.type === 'internet_banking_scb') {
          return 'อินเตอร์เน็ตแบงก์กิ้ง (SCB)'
        } else if (source.type === 'internet_banking_bay') {
          return 'อินเตอร์เน็ตแบงก์กิ้ง (BAY)'
        } else if (source.type === 'internet_banking_bbl') {
          return 'อินเตอร์เน็ตแบงก์กิ้ง (BBL)'
        } else if (source.type === 'internet_banking_ktb') {
          return 'อินเตอร์เน็ตแบงก์กิ้ง (KTB)'
        } else if (source.type === 'promptpay') {
          return 'พร้อมเพย์'
        } else if (source.type === 'credit_card') {
          return 'บัตรเครดิต'
        }
      }
    },
    chargeId() {
      return this.$route.query.chargeId
    },
  },
  mounted() {
    this.getOrderReceived()
  },
  methods: {
    goToShop() {
      this.$router.push('/pickup/menulists')
    },
    goToPayment() {
      sessionStorage.setItem('amount', this.totalAmountForPayment)
      this.$router.push('/payment')
    },
    getOrderReceived() {
      payment({
        url: '/omise/payment/v1.0/order/received/' + this.chargeId,
        method: 'get',
      }).then((res) => {
        this.charge = res.data.data

        if (this.charge === null || res.data.error.code !== 0) {
          this.status = 'error'
          this.alertMessage = 'The order is invalid.'
        } else {
          if (this.charge.status === 'failed') {
            this.status = 'error'
            this.alertMessage = this.charge.failure_message
          } else if (this.charge.status === 'expired') {
            this.status = 'error'
            this.alertMessage = 'The order was expired.'
          } else if (this.charge.status === 'reversed') {
            this.status = 'error'
            this.alertMessage = 'The order was unhold.'
          } else if (
            this.charge.authorized === true &&
            this.charge.paid === true
          ) {
            this.status = 'success'
            this.alertMessage = 'The order was paid successfully.'
          } else if (
            this.charge.authorized === true &&
            this.charge.capture === false
          ) {
            this.status = 'error'
            this.alertMessage =
              'The order was authorized successfully, but not yet paid.'
          } else if (this.charge.status === 'pending') {
            this.status = 'pending'
            this.alertMessage = 'Pending.'
          }
        }
      })
    },
  },
}
</script>
