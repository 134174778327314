import axios from 'axios'
import util from '../util/asymmetric'

let tokenAsymmetric = util.asymmetric()
let token = localStorage.getItem('token')


export default axios.create({
  //baseURL : 'http://localhost:7001',
  baseURL: process.env.VUE_APP_PAYMENT_GATEWAY_URL,
  headers: {
    token: tokenAsymmetric,
    authorization : token
  },
})